/**
 * 2022-06-08T10:49:11
 * @param dateStr
 * @param format
 */
export function getDateOfT(dateStr: string, format: string = 'yyyy-MM-dd hh:mm:ss'): string {
    if (!dateStr) {
        return '';
    }
    const date = new Date(dateStr);
    // 注意js里面的getMonth是从0开始的
    const o: any = {
        'y+': date.getFullYear(),
        'M+': date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
        'd+': date.getDate(),
        'h+': date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            );
        }
    }
    return format;

}

export function getYYYMMDD(date: Date) {

// 获取当前月份
    var nowMonth: any = date.getMonth() + 1;

// 获取当前是几号
    var strDate: any = date.getDate();

// 添加分隔符“-”
    var seperator: any = "-";

// 对月份进行处理，1-9月在前面添加一个“0”
    if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
    }

// 对月份进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }

// 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
    return date.getFullYear() + seperator + nowMonth + seperator + strDate;
}

export function getCurrentTime(date: Date): string {
    var year = date.getFullYear() //年
    var month = repair(date.getMonth() + 1);//月
    var day = repair(date.getDate());//日
    var hour = repair(date.getHours());//时
    var minute = repair(date.getMinutes());//分
    var second = repair(date.getSeconds());//秒

    //当前时间
    var curTime = year + "-" + month + "-" + day
        + " " + hour + ":" + minute + ":" + second;
    return curTime;
}

//若是小于10就加个0

function repair(i: number) {
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}
