import {post, get, ResponseType, URL} from "@/utils/axios";
import {AI_CHAT_LONGTEXT, OPENAI_CHAT_summary} from "@/utils/api/url";

export const send = (data: {
    message: string
}) => post(URL.OPENAI_CHAT_SEND, {data: data})

export const longtext = (data: {
    longtext: string
}) => post(URL.AI_CHAT_LONGTEXT, {data: data})

export const continueWriting = (data: {
    paragraph: string,
    context?: string
}) => post(URL.OPENAI_CHAT_continueWriting, {data: data})

export const beautifyParagraph = (data: {
    paragraph: string,
    context?: string
}) => post(URL.OPENAI_CHAT_beautifyParagraph, {data: data})
export const summary = (data: {
    content: string
}) => post(URL.OPENAI_CHAT_summary, {data: data})

export const getHistory = (data: {
    startIndex?: number
    size?: number
}) => get(URL.OPENAI_CHAT_HISTORY, {params: data})

export const clear = () => post(URL.OPENAI_CHAT_CLEAR)









